<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox">
      <div class="flex justify-between">
        <!-- 左侧 -->
        <div class="left_box">
          <div class="menuList">
            <div v-for="(item, i) in toolTypeList"
                 :key="item.typeCode"
                 @click="handleTab(i, item.toolTypeCode)"
                 :class="
                isActive == i
                  ? 'menu_item bgwhite text-cut'
                  : 'menu_item text-cut'
              ">
              {{ item.toolTypeName }}
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right_box bbox pt20 plr15 pb20 positionr flex1">
          <div v-if="toolsList.length == 0"
               class="noMessage positiona">
            <img src="../../assets/images/kong.png"
                 alt=""
                 width="100" />
            <div class="f14 textc mt15">{{$t('noData')}}</div>
          </div>
          <div class="list text666 f13"
               v-else>
            <div v-for="tool in toolsList"
                 :key="tool.toolCode"
                 @click="showToolsOnline(tool)">
              {{ tool.toolName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      toolTypeList: [],
      isActive: 0,
      nodeList: [1],
      toolsList: [],
    };
  },
  methods: {
    handleTab (i, typeCode) {
      this.isActive = i;
      //显示列表
      var that = this;
      that.toolsList = [];
      this.allToolsList.forEach((element) => {
        if (element.toolTypeCode == typeCode) {
          that.toolsList.push(element);
        }
      });
    },
    showToolsOnline (tool) {
      var that = this;
      if (tool.url != undefined && tool.url != "") {
        that.$router.push({
          path: "/previewFile",
          query: {
            url: tool.url,
            content: "",
            typeCode: tool.toolCode,
            typeName: tool.toolName,
            machineType: tool.toolTypeName,
          },
        });
      } else {
        that.$message(tips1);
      }
    },
    loadToolList () {
      //加载工具相关资料
      var that = this;
      var sendData = {};
      this.$http.post("tool/queryToolInfo", sendData).then(function (res) {
        if ((res.data.code = 200)) {
          that.toolTypeList = res.data.data.toolTypeList;
          that.toolsList = res.data.data.toolList;
          that.allToolsList = res.data.data.toolList;
        }
      });
    },
  },
  created () {
    this.loadToolList();
  },
};
</script>

<style scoped>
/* // 左侧菜单列表 */
.left_box {
  width: 110px;
  height: 100%;
  background-color: #efefef;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.menuList .menu_item {
  padding: 12px 10px;
  cursor: pointer;
  color: #666;
}
.list > div {
  padding: 8px 0;
  border-bottom: 1px solid #ededed;
}
</style>